import StateFetchRequestPayload from '../../common/models/websocket/payloads/state/StateFetchRequestPayload';
import StateFetchResponsePayload from '../../common/models/websocket/payloads/state/StateFetchResponsePayload';
import StateStoreRequestPayload from '../../common/models/websocket/payloads/state/StateStoreRequestPayload';
import StateStoreResponsePayload from '../../common/models/websocket/payloads/state/StateStoreResponsePayload';
import WebsocketEvent from '../../common/models/websocket/WebsocketEvent';
import WebsocketApiService from '../../common/services/WebsocketApiService';
import AuthService from '../../common/services/AuthService';
import Logger from '../../utils/logging/Logger';
import TourDetailsState from '../../tour/models/state/TourDetailsState';
import { AppRoutingParams } from '../models/AppRoutingParams';
import TrackingService from '../../tracking/services/TrackingService';
import { debounce, isEqual } from 'lodash';
import {
  lmaReduxStore,
  AppState,
  OverviewState,
  DashboardState,
  RootState,
  selectActiveView,
  selectOverviewRoutingParams,
  selectDashboardRoutingParams,
  selectTourDetailsRoutingParams,
  updateFromRoutingParams,
} from '@redux';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

export default class DeepLinkService {
  store: ToolkitStore<RootState>;
  logger: Logger = Logger.getInstance('DeepLinkService');
  checkTimeoutHandle?: NodeJS.Timeout = null;
  storeUnsubscribe: Function | null | undefined = null;
  lastState?: {
    appState: AppState;
    overviewState: OverviewState;
    tourDetailsState: TourDetailsState;
    dashboardState: DashboardState;
  };
  lastKnownRoutingParams;
  lastKnownRoutingHash: string | null | undefined = null;

  constructor(
    private readonly authService: AuthService,
    private readonly websocketApiService: WebsocketApiService,
    private readonly trackingService: TrackingService
  ) {
    this.store = lmaReduxStore;
  }

  async init() {
    this.onStoreChanged = debounce(this.onStoreChanged.bind(this), 1000);
    this.storeAndUpdateHash = this.storeAndUpdateHash.bind(this);
    this.storeUnsubscribe = this.store.subscribe(this.onStoreChanged);
    this.websocketApiService.socketEventFormatter.registerEventType('state.fetch', StateFetchResponsePayload);
    this.websocketApiService.socketEventFormatter.registerEventType('state.store', StateStoreResponsePayload);
    window.addEventListener('popstate', this.processUrlParams.bind(this));
  }

  destruct() {
    if (this.storeUnsubscribe) {
      this.storeUnsubscribe();
    }
  }

  async getCurrentHash(): Promise<string> {
    return await this.storeAndUpdateHash();
  }

  private onStoreChanged() {
    if (this.isStoreChanged()) {
      this.storeAndUpdateHash();
    }
  }

  private isStoreChanged(): boolean {
    const routingParams = this.getAppRoutingParams();
    return !isEqual(this.lastKnownRoutingParams, routingParams);
  }

  private async storeAndUpdateHash(): Promise<string> {
    try {
      const routingParams = this.getAppRoutingParams();
      if (isEqual(this.lastKnownRoutingParams, routingParams)) {
        return;
      } else {
        this.logger.debug('Updating routingParams', routingParams);
        this.trackView();
        const uuid: string = await this.storeRoutingParameters(routingParams);
        this.lastKnownRoutingParams = routingParams;
        this.updateHash(uuid);
        this.lastKnownRoutingHash = uuid;
      }
    } catch (e) {
      this.logger.error('failed on store', e);
    }
    return Promise.resolve(this.lastKnownRoutingHash);
  }

  private updateHash(uuid: string) {
    const urlParams = new URLSearchParams(window.location.search);
    Array.from(urlParams.keys())
      .filter((k) => !['debug', 'digilabTourDetails', 'barcode'].includes(k))
      .forEach((k) => urlParams.delete(k));
    urlParams.set('v', uuid);
    window.history.pushState({}, document.title, `${location.pathname}?${urlParams.toString()}`);
  }

  private trackView() {
    const viewData = { ...this.getAppRoutingParams() };
    if (!viewData.overview) viewData.overview = null;
    if (!viewData.tourDetails) viewData.tourDetails = null;
    if (!viewData.dashboard) viewData.dashboard = null;
    this.trackingService.sendEvent('last-mile-analytics.view', {
      viewData: viewData,
    });
  }

  private async storeRoutingParameters(routingParams: AppRoutingParams): Promise<string> {
    const requestEvent = new WebsocketEvent({
      name: 'state.store',
      payload: StateStoreRequestPayload.buildFromObject(routingParams),
    });
    this.logger.debug('Store routing params.', requestEvent.payload);
    const responseEvent: WebsocketEvent = await this.websocketApiService.request(requestEvent);

    if (responseEvent.payload && responseEvent.payload instanceof StateStoreResponsePayload) {
      const payload: StateStoreResponsePayload = responseEvent.payload;
      this.logger.debug('Stored routing params.', payload.uuid);
      return payload.uuid;
    } else {
      this.logger.error('Unexpected response from api.', responseEvent);
      throw new Error('Unexpected response from api:' + JSON.stringify(responseEvent));
    }
  }

  private async fetchRoutingParams(uuid: string): Promise<AppRoutingParams> {
    const requestEvent = new WebsocketEvent({
      name: 'state.fetch',
      payload: new StateFetchRequestPayload({ uuid }),
    });
    const responseEvent: WebsocketEvent = await this.websocketApiService.request(requestEvent);
    if (responseEvent.payload && responseEvent.payload instanceof StateFetchResponsePayload) {
      const payload: StateFetchResponsePayload = responseEvent.payload;
      this.logger.debug('Fetched routing params.', payload.content);
      return payload.getContentAsObject();
    } else {
      this.logger.error('Unexpected response from api.', responseEvent);
      throw new Error('Unexpected response from api: ' + JSON.stringify(responseEvent));
    }
  }

  private getAppRoutingParams(): AppRoutingParams {
    const viewMode = selectActiveView(lmaReduxStore.getState());
    const params = {
      viewMode,
      overview: selectOverviewRoutingParams(lmaReduxStore.getState()),
      tourDetails: selectTourDetailsRoutingParams(lmaReduxStore.getState()),
      dashboard:
        viewMode === 'dashboard' || viewMode === 'tourDetails'
          ? selectDashboardRoutingParams(lmaReduxStore.getState())
          : null,
    };
    return params;
  }

  private async applyRoutingParamsId(uuid: string): Promise<AppRoutingParams> {
    const routingParams = await this.fetchRoutingParams(uuid);
    await this.applyRoutingParams(routingParams);
    return routingParams;
  }

  private async applyRoutingParams(routingParams: AppRoutingParams) {
    if (
      !routingParams.viewMode ||
      !this.authService.can(`ui.general.view-modes.${routingParams.viewMode}`) ||
      routingParams.viewMode === 'loading'
    ) {
      if (this.authService.can('ui.general.view-modes.dashboard')) {
        routingParams.viewMode = 'dashboard';
      } else {
        routingParams.viewMode = 'overview';
      }
    }
    lmaReduxStore.dispatch(updateFromRoutingParams(routingParams));
    this.logger.info('Routing params set.', routingParams);
  }

  async processUrlParams(): Promise<void> {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('v')) {
        await this.applyRoutingParamsId(urlParams.get('v'));
      } else {
        this.applyDefaults();
      }
    } catch (e) {
      this.logger.error('Could not load routing params.', e);
      this.applyDefaults();
    }
  }

  async applyDefaults() {
    this.storeAndUpdateHash();
  }
}
