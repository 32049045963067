import React from 'react';
import { Alert } from 'reactstrap';
import classNames from 'classnames';

import Styles from './MigrationBanner.module.scss';

const MigrationBanner = ({ className }) => {
  const location = window.location.hostname;

  // Only show the banner for AWS deployments (these URLS contain 'digital')
  if (!location.includes('digital')) {
    return null;
  }

  return (
    <Alert className={classNames(className, Styles.MigrationBanner)}>
      <p>
        Das LMA / GU Dashboard zieht in die Google Cloud und ist nun unter folgendem Link erreichbar:{' '}
        <a href='https://last-mile-analytics.hc.de' className='alert-link' target='_blank'>
          last-mile-analytics.hc.de
        </a>{' '}
        .
      </p>
      <p>
        Vorrübergehend wird <i>last-mile-analytics.hermesgermany.digital</i> weiterhin nutzbar bleiben und zum{' '}
        <b>31.01.2025</b> abgeschaltet. <b>Bitte an die Lesezeichenaktualisierung denken!</b> Nutzereinstellungen (wie
        z.B. angelegte KPI Kacheln) werden mit Stand 13.01.2025 einmalig übernommen und anschließend nicht zwischen
        neuer und alter URL synchronisiert.
      </p>
    </Alert>
  );
};

export default MigrationBanner;
